<template>
	<div class="info-content">
		<Temporary />
		<div>
			<ul style="padding-left:24px;">
				<li>
						User Ticket Statistic
				</li>
				<li>
						Recent Updated Ticket
				</li>
				<li>
						Recent Reported Ticket
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
// TODO Ticket Statistic for User
import Temporary from "@components/common/Temporary.vue"
export default {
	components : {
		Temporary
	} ,
	props : {
		company : {
			type: Object ,
			default : () => []
		} ,
		user : {
			type : null,
			default : () => []
		}
	}
}
</script>

<style lang="less" scoped>
.info-content {
	padding : 16px;
}
</style>
